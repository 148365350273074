import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { domAnimation, LazyMotion } from 'framer-motion'
import { Videobot, i18nInstance, Trigger, WidgetProvider, useWidget } from '@videobotcom/embed'

import './preview.css'

export const VideobotWidgetPresentation = ({ initConfig, preview, slideId }) => {
	const {
		setConfig,
		isOpen,
		enablePreview,
		openWidget,
		setActiveSlide,
		setTimeline,
		config,
		disableAnalytics,
		handleMute,
		isMuted,
	} = useWidget()

	useEffect(() => {
		setConfig(initConfig)

		if (initConfig?.slides?.[0]) {
			const firstSlide =
				initConfig.slides.find((o) => o.category === 'INTRO') || initConfig.slides[0]
			const selectedSlide = slideId
				? initConfig.slides.find((o) => `${o.id}` === `${slideId}`)
				: null
			setActiveSlide(selectedSlide || firstSlide)
			setTimeline([selectedSlide || firstSlide])
			try {
				handleMute(true)
			} catch {
				// TODO: Check video exist from embed instead
				// ignore
			}
		}
	}, [initConfig, preview, slideId])

	useEffect(() => {
		if (preview && config) {
			openWidget({ event_type: 'auto', mute: isMuted })
			enablePreview()
		}
	}, [config, preview, isMuted])

	useEffect(() => {
		disableAnalytics()
	}, [])

	return (
		<I18nextProvider i18n={i18nInstance}>
			{config && (
				<LazyMotion features={domAnimation}>
					<div className="videobot">
						{config && !isOpen && !preview && (
							<Trigger
								onClick={() =>
									openWidget({
										event: 'video interaction',
									})
								}
							/>
						)}
						{config && <Videobot uuid="PREVIEW" />}
					</div>
				</LazyMotion>
			)}
		</I18nextProvider>
	)
}

export const VideobotWidget = ({ config, preview, slideId }) => {
	return (
		<WidgetProvider
			hydratedState={{
				uuid: 'PREVIEW',
				preview,
				config,
				requestUrl: location.href,
				features: config?.features || [],
				isOpen: preview,
				disabledAnalytics: true,
			}}
		>
			<div className="videobot-preview">
				<VideobotWidgetPresentation
					preview={preview}
					initConfig={config}
					slideId={slideId}
				/>
			</div>
		</WidgetProvider>
	)
}
