import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldAvatar from '@/components/FieldAvatar'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FormRow from '@/components/FormRow'
import FormSection from '@/components/FormSection/FormSection'
import { Col, Row } from '@/components/Grid'
import PropTypes from '@/lib/propTypes'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'

import DeleteUserModal from '../DeleteUserModal'
import ResetPaswordModal from '../ResetPaswordModal'

const LANGS = [
	{
		id: 'en',
		name: 'English',
	},
	{
		id: 'fi',
		name: 'Finnish',
	},
]

const UserProfileFormContents = ({ userData, role, onSubmit }, ref) => {
	const { t } = useTranslation(['account', 'user'])
	const formRef = useRef(null)
	const [showDeleteUser, setShowDeleteUser] = useState(false)
	const [showResetPassword, setShowResetPassword] = useState(false)
	const activeAccount = useCurrentAccount()
	const user = useCurrentUser()

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: {
			profilePictureId: userData?.profilePictureId,
		},
	})

	const ROLES = [
		{
			name: 'Reseller',
			label: t('user:roles.reseller'),
			show: user.isSuperuser || ['Owner'].includes(activeAccount.role),
			disabled: true,
		},
		{
			name: 'Admin',
			label: t('user:roles.admin'),
			show: user.isSuperuser || ['Owner', 'Admin'].includes(activeAccount.role),
		},
		{
			name: 'Owner',
			label: t('user:roles.owner'),
			show: ['Admin'].includes(activeAccount.role),
		},
		{
			name: 'Member',
			label: t('user:roles.member'),
			show: user.isSuperuser || ['Owner', 'Admin'].includes(activeAccount.role),
		},
	]

	const disableDetailsFields =
		!user.isSuperuser &&
		['Admin', 'Owner'].includes(activeAccount.role) &&
		user.id !== userData.id

	const disableManagementFields = !user.isSuperuser && role.role === 'Owner'

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		},
	}))

	return (
		<React.Fragment>
			<form ref={formRef} onSubmit={handleSubmit((data) => onSubmit(data))}>
				<FormSection
					title={t('account:section.userProfile.label')}
					description={t('account:section.userProfile.description')}
				>
					{/* Profile Image */}
					<FormRow title={t('account:group.profileImage.label')}>
						<FieldAvatar
							name="profilePicture"
							defaultValue={userData.profilePicture?.url}
							valueName="profilePictureId"
							setValue={setValue}
							accountId={activeAccount.id}
							disabled={disableDetailsFields}
							label={t('account:group.profileImage.label')}
						/>
					</FormRow>
					{/* Name */}
					<FormRow title={t('account:group.name.label')}>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.firstName.label')}>
									<FieldText
										register={register}
										placeholder={t('account:fields.firstName.placeholder')}
										name="firstName"
										type="text"
										error={errors.firstName}
										defaultValue={userData.firstName}
										disabled={disableDetailsFields}
										required={!disableDetailsFields}
									/>
								</FormField>
							</Col>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.lastName.label')}>
									<FieldText
										register={register}
										placeholder={t('account:fields.lastName.placeholder')}
										name="lastName"
										type="text"
										error={errors.lastName}
										defaultValue={userData.lastName}
										disabled={disableDetailsFields}
										required={!disableDetailsFields}
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>
					{/* Email & Phone */}
					<FormRow
						title={t('account:group.emailAndPhone.label')}
						description={t('account:group.emailAndPhone.description')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.email.label')}>
									<FieldText
										register={register}
										placeholder={t('account:fields.email.placeholder')}
										name="email"
										type="email"
										error={errors.email}
										defaultValue={userData.email}
										disabled={disableDetailsFields}
										required={!disableDetailsFields}
									/>
								</FormField>
							</Col>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.phoneNumber.label')}>
									<FieldText
										register={register}
										placeholder={t('account:fields.phoneNumber.placeholder')}
										name="phoneNumber"
										type="text"
										error={errors.phoneNumber}
										defaultValue={userData.phoneNumber}
										disabled={disableDetailsFields}
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>
					{/* Language */}
					<FormRow
						title={t('account:fields.language.label')}
						description={t('account:fields.language.description')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.language.label')}>
									<FieldSelect
										register={register}
										placeholder={t('account:fields.language.placeholder')}
										name="language"
										type="text"
										error={errors.language}
										required
										options={LANGS.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={userData.language}
										disabled={disableDetailsFields}
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>

					{/* Other Users Only */}
					{user.id !== userData.id && (
						<React.Fragment>
							{/* Role */}
							<FormRow
								title={t('account:fields.role.label')}
								description={t('account:fields.role.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField label={t('account:fields.role.label')}>
											<FieldSelect
												register={register}
												placeholder={t('account:fields.role.placeholder')}
												name="role"
												type="text"
												error={errors.role}
												disabled={
													user.id === userData.id ||
													disableManagementFields
												}
												required
												options={ROLES.filter((o) => o.show).map(
													(item) => ({
														value: item.name,
														label: item.label,
														disabled: item.disabled,
														item,
													}),
												)}
												setValue={setValue}
												defaultValue={role.role}
												size="large"
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>

							{/* Reset Password */}
							<FormRow
								title={t('account:group.resetPassword.label')}
								description={t('account:group.resetPassword.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<Button
											variant="secondary"
											onClick={(e) => {
												setShowResetPassword(true)
												e.preventDefault()
											}}
										>
											{t('account:group.resetPassword.action')}
										</Button>
									</Col>
								</Row>
							</FormRow>

							{/* Delete */}
							<FormRow
								title={t('account:group.deleteUser.label')}
								description={t('account:group.deleteUser.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<Button
											variant="red"
											onClick={(e) => {
												setShowDeleteUser(true)
												e.preventDefault()
											}}
										>
											{t('account:group.deleteUser.label')}
										</Button>
									</Col>
								</Row>
							</FormRow>
						</React.Fragment>
					)}
				</FormSection>
			</form>
			<AnimatePresence>
				{showDeleteUser && (
					<DeleteUserModal user={userData} onClose={() => setShowDeleteUser(false)} />
				)}
				{showResetPassword && (
					<ResetPaswordModal
						user={userData}
						onClose={() => setShowResetPassword(false)}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

const UserProfileForm = forwardRef(UserProfileFormContents)
UserProfileForm.displayName = 'UserProfileForm'

UserProfileForm.propTypes = {
	userData: PropTypes.object,
	role: PropTypes.object,
	onSubmit: PropTypes.func,
}

export default UserProfileForm
