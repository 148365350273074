import { useTranslation } from 'react-i18next'

import DataTable from '@/components/DataTable'
import More, { MoreButton } from '@/components/More'
import Thumbnail from '@/components/Thumbnail'
import { breakpoints } from '@/lib/theme'
import {
	AnalyticsFieldType,
	BotLiveStatusType,
	BotStatusType,
	EventChannelType,
} from '@/api/videobot.schemas'
import { usePowerUserMode } from '@/modules/session/auth.store'
import Badge from '@/components/Badge'

import { AnalyticsDetails } from './AnalyticsDetails'

const AnalyticsMenu = ({ onPreview, onEdit }) => {
	const { t } = useTranslation(['analytics'])

	return (
		<More>
			<MoreButton onClick={onPreview}>{t('preview')}</MoreButton>
			<MoreButton onClick={onEdit}>{t('edit')}</MoreButton>
		</More>
	)
}

const fieldEnabled = (analyticsDisplayFields, fieldName) => {
	return analyticsDisplayFields[fieldName] === true || !(fieldName in analyticsDisplayFields)
}

export const AnalyticsList = ({
	data,
	report,
	onBotPreview,
	onBotEdit,
	params,
	channel,
	analyticsDisplayFields,
	hasSessions,
	newCtrFormula,
	onPageChange,
	onSort,
	isPaginated = true,
	sliderAnalytics = null,
}) => {
	const { t } = useTranslation(['analytics'])
	const { powerUserMode } = usePowerUserMode()
	const isSliderAnalytics = channel === EventChannelType.slider
	let filteredData = [...(data.records || data)]

	// client filters
	if (params.status) {
		const statusFilter = params.status.split(',').map((status) => status.trim())
		filteredData = filteredData.filter(
			(row) => statusFilter.includes(row.bot.status) || statusFilter.includes(row.liveStatus),
		)
	}

	const customStyles = {
		rows: {
			style: {
				minHeight: '64px', // override the row height
			},
		},
		expanderButton: {
			style: {
				backgroundColor: 'transparent',
				'&:hover:not(:disabled)': {
					backgroundColor: 'transparent',
				},
				'&:focus': {
					backgroundColor: 'transparent',
				},
			},
		},
	}

	const columns = [
		{
			width: '4.7rem',
			cell: (row) => {
				return <Thumbnail url={row.bot.posterUrl} name="video" variant="square" />
			},
		},
		{
			id: 'name',
			name: t('name'),
			selector: (row) => row.bot.name,
			sortable: true,
			sortFunction: (a, b) =>
				a.bot.name.localeCompare(b.bot.name, undefined, { sensitivity: 'base' }),
			minWidth: '200px',
		},
		{
			id: 'opened',
			name: t('analytics:timesOpened'),
			selector: (row) => {
				// New CTR/Engagement formula, since 04-09-2024
				if (newCtrFormula) {
					return row.opened
				}
				if (channel === EventChannelType.direct) {
					return row.directIntroViewed
				}
				if (channel === EventChannelType.iframe) {
					return row.iframeIntroViewed
				}
				if (channel === EventChannelType.widget) {
					return row.widgetIntroViewed
				}
				if (channel === EventChannelType.slider) {
					return row.sliderIntroViewed
				}
			},
			sortable: true,
		},
		{
			id: 'watermark',
			name: t('analytics:watermark'),
			selector: (row) => {
				return row.watermarkClicked
			},
			sortable: true,
			omit: !powerUserMode,
		},
		{
			id: 'liveStatus',
			name: t('live_status'),
			selector: (row) => {
				return row.liveStatus
			},
			cell: (row) => {
				const bot = row.bot
				if (bot.status === BotStatusType.Draft) {
					return <Badge variant="default">{t(bot.status.toLowerCase())}</Badge>
				}
				if (bot.status === BotStatusType.Deactivated) {
					return <Badge variant="red">{t(bot.status.toLowerCase())}</Badge>
				}
				if (bot.status === BotStatusType.Published) {
					if (row.liveStatus === BotLiveStatusType.offline) {
						return <Badge variant="red">{t(row.liveStatus.toLowerCase())}</Badge>
					}
					if (row.liveStatus === BotLiveStatusType.inactive) {
						return <Badge variant="muted">{t(row.liveStatus.toLowerCase())}</Badge>
					}
					return <Badge variant="green">{t(row.liveStatus.toLowerCase())}</Badge>
				}
			},
			sortable: true,
		},
		{
			id: 'session',
			name: t('analytics:session'),
			selector: (row) => {
				if (channel === EventChannelType.direct) {
					return row.session
				}
				if (channel === EventChannelType.iframe) {
					return row.session
				}
				if (channel === EventChannelType.widget) {
					return row.session
				}
			},
			sortable: true,
			omit: !hasSessions || isSliderAnalytics,
		},
		{
			name: t('analytics:engaging'),
			selector: (row) => {
				// New CTR/Engagement formula, since 04-09-2024
				if (newCtrFormula) {
					return (row.engaged / row.opened) * 100 || 0
				}
				if (channel === EventChannelType.direct) {
					return (row.introActions / row.directIntroViewed) * 100 || 0
				}
				if (channel === EventChannelType.iframe) {
					return (row.introActions / row.iframeIntroViewed) * 100 || 0
				}
				if (channel === EventChannelType.widget) {
					return (row.introActions / row.widgetIntroViewed) * 100 || 0
				}
				if (channel === EventChannelType.slider) {
					return (row.introActions / row.sliderIntroViewed) * 100 || 0
				}
			},
			cell: (row) => {
				if (newCtrFormula) {
					return `${((row.engaged / row.opened) * 100 || 0).toFixed(1)}%`
				}
				if (channel === EventChannelType.direct) {
					return `${((row.introActions / row.directIntroViewed) * 100 || 0).toFixed(1)}%`
				}
				if (channel === EventChannelType.iframe) {
					return `${((row.introActions / row.iframeIntroViewed) * 100 || 0).toFixed(1)}%`
				}
				if (channel === EventChannelType.widget) {
					return `${((row.introActions / row.widgetIntroViewed) * 100 || 0).toFixed(1)}%`
				}
				if (channel === EventChannelType.slider) {
					return `${((row.introActions / row.sliderIntroViewed) * 100 || 0).toFixed(1)}%`
				}
			},
			sortable: true,
		},
		{
			id: 'loaded',
			name: t('analytics:timesLoaded'),
			selector: (row) => {
				if (channel === EventChannelType.direct) {
					return row.iframeViewed
				}
				if (channel === EventChannelType.iframe) {
					return row.iframeViewed
				}
				if (channel === EventChannelType.widget) {
					return row.widgetViewed
				}
				if (channel === EventChannelType.slider) {
					return row.widgetViewed // use same field as widget
				}
			},
			sortable: true,
			omit: hasSessions,
		},
		{
			name: t('analytics:ctr'),
			selector: (row) => {
				// New CTR/Engagement formula, since 04-09-2024
				if (newCtrFormula) {
					return (row.opened / row.session) * 100 || 0
				}

				if (channel === EventChannelType.direct) {
					return (
						(row.directIntroViewed / (hasSessions ? row.session : row.iframeViewed)) *
							100 || 0
					)
				}
				if (channel === EventChannelType.iframe) {
					return (
						(row.iframeIntroViewed / (hasSessions ? row.session : row.iframeViewed)) *
							100 || 0
					)
				}
				if (channel === EventChannelType.widget) {
					return (
						(row.widgetIntroViewed / (hasSessions ? row.session : row.widgetViewed)) *
							100 || 0
					)
				}
				if (channel === EventChannelType.slider) {
					return (
						(row.sliderIntroViewed / sliderAnalytics.sliderSession) * 100 || 0
					).toFixed(1)
				}
			},
			cell: (row) => {
				// New CTR/Engagement formula, since 04-09-2024
				if (newCtrFormula) {
					return `${((row.opened / row.session) * 100 || 0).toFixed(1)}%`
				}

				if (channel === EventChannelType.direct) {
					return `${(
						(row.directIntroViewed / (hasSessions ? row.session : row.iframeViewed)) *
							100 || 0
					).toFixed(1)}%`
				}
				if (channel === EventChannelType.iframe) {
					return `${(
						(row.iframeIntroViewed / (hasSessions ? row.session : row.iframeViewed)) *
							100 || 0
					).toFixed(1)}%`
				}
				if (channel === EventChannelType.widget) {
					return `${(
						(row.widgetIntroViewed / (hasSessions ? row.session : row.widgetViewed)) *
							100 || 0
					).toFixed(1)}%`
				}
				if (channel === EventChannelType.slider) {
					return `${((row.sliderIntroViewed / sliderAnalytics.sliderSession) * 100 || 0).toFixed(1)}%`
				}
			},
			sortable: true,
		},
		{
			name: t('analytics:ctaClicks'),
			selector: (row) => row.cta,
			sortable: true,
			omit: !fieldEnabled(analyticsDisplayFields, AnalyticsFieldType.cta),
		},
		{
			name: t('analytics:newContacts'),
			selector: (row) => row.contacted,
			sortable: true,
			omit: !fieldEnabled(analyticsDisplayFields, AnalyticsFieldType.email),
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<AnalyticsMenu
						row={row}
						onPreview={() => onBotPreview(row.bot)}
						onEdit={() => onBotEdit(row.bot)}
					/>
				)
			},
		},
	]

	return (
		<DataTable
			columns={columns}
			data={filteredData}
			expandableRows
			expandOnRowClicked
			expandableRowDisabled={(row) => row.disabled}
			expandableRowsComponent={(props) => (
				<AnalyticsDetails data={props.data} params={params} channel={channel} />
			)}
			pagination={!report && isPaginated}
			customStyles={customStyles}
			pageSize={params.paginationSize}
			expandableRowExpanded={() => Boolean(report)}
			paginationServer={false}
			onPageChange={onPageChange}
			onSort={onSort}
			page={params.paginationPage}
			defaultSortFieldId={params.orderBy}
			defaultSortAsc={params.orderDirection === 'asc'}
		/>
	)
}
