import { clamp, capitalize } from 'lodash-es'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CheckPicker from 'rsuite/esm/CheckPicker'
import SelectPicker from 'rsuite/esm/SelectPicker'
import { format } from 'date-fns'

import Loader from '@/components/Loader'
import { useAuth } from '@/modules/session/auth.store'
import Error from '@/layout/Error'
import { useReadAllBotsStatus, useReadCurrentUserAccounts } from '@/api/videobot'
import FieldText from '@/components/FieldText'
import { RoleCheck } from '@/modules/session/auth.component'
import { BotLiveStatusType, BotStatusType, RoleType } from '@/api/videobot.schemas'
import DataTable from '@/components/DataTable'
import Thumbnail from '@/components/Thumbnail'
import Popover from '@/components/Popover'
import Badge from '@/components/Badge'

import View from '../../layout/View'

const INITIAL_PARAMS = { order_by: 'id', order_direction: 'desc', page: 1 }
const BotStatusFilter = [['All', null], ...Object.entries(BotLiveStatusType)].map(
	([label, value]) => ({
		label: capitalize(label),
		value,
	}),
)

const LiveStatusContents = () => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS)
	const { register, handleSubmit } = useForm()
	const page = Number(searchParams.get('page'))
	const orderBy = searchParams.get('order_by')
	const orderDirection = searchParams.get('order_direction')
	const botName = searchParams.get('bot_name')
	const { data: accounts } = useReadCurrentUserAccounts()
	const [accountFilter, setAccountFilter] = useState([])
	const [botStatusFilter, setBotStatusFilter] = useState(null)

	const size = 30

	const { t } = useTranslation(['videobot'])

	const { activeAccountId } = useAuth()

	const { data, error, isFetching, refetch } = useReadAllBotsStatus(
		{
			account_ids: accountFilter.length ? accountFilter.join(',') : null,
			size,
			page,
			order_direction: orderDirection,
			order_by: orderBy,
			bot_name: botName,
			bot_status: botStatusFilter,
			tag_ids: null,
		},
		{ query: { keepPreviousData: true } },
	)

	const onPageChange = useCallback(
		(newPage) => {
			if (!data) {
				return
			}
			const params = {
				order_by: orderBy,
				order_direction: orderDirection,
				page: clamp(newPage, 1, data.metadata.pageCount),
			}
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
		},
		[setSearchParams, data, orderBy, orderDirection],
	)

	const onSort = useCallback(
		(selectedColumn, sortDirection) => {
			const params = {
				order_by: selectedColumn?.id,
				order_direction: sortDirection,
				page: 1,
			}

			setSearchParams((prev) => ({
				...Object.fromEntries(prev),
				order_by: selectedColumn?.id,
				order_direction: sortDirection,
				page,
			}))

			setTimeout(() => {
				setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
			}, 100)
		},
		[setSearchParams, page],
	)

	const onSearch = (data) => {
		setSearchParams((prev) => {
			if (data.bot_name) {
				prev.set('bot_name', data.bot_name)
			} else {
				prev.delete('bot_name')
			}
			prev.set('page', 1)
			return prev
		})

		refetch({
			account_id: activeAccountId,
			size,
			page: Number(searchParams.get('page')),
			order_direction: searchParams.get('order_direction'),
			order_by: searchParams.get('order_by'),
			bot_name: searchParams.get('bot_name'),
		})
	}

	if (error) {
		return <Error />
	}

	if (data) {
		const bots = data.records
		const handleEdit = (row) => {
			navigate(`/dashboard/videobots/${row.id}${searchParams}`)
		}

		const columns = [
			{
				width: '4rem',
				cell: (row) => {
					return <Thumbnail url={row.posterUrl} fallbackIcon="no-video" />
				},
			},
			{
				name: 'ID',
				width: '4rem',
				cell: (row) => {
					return row.id
				},
			},
			{
				id: 'name',
				name: t('videobot:botFields.name.label'),
				selector: (row) => row.name,
				sortable: true,
				width: '10rem',
				cell: (row) => {
					return (
						<div onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
							<Popover
								toggler={
									<span
										style={{
											maxWidth: '9rem',
											display: 'inline-block',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{row.name}
									</span>
								}
								triggerOnHover
							>
								{row.name}
							</Popover>
						</div>
					)
				},
			},
			{
				id: 'status',
				name: t('videobot:botFields.status.label'),
				selector: (row) => row.status,
				cell: (row) => {
					if (row.status === BotStatusType.Draft) {
						return <Badge variant="default">{t(row.status.toLowerCase())}</Badge>
					}
					if (row.status === BotStatusType.Deactivated) {
						return <Badge variant="red">{t(row.status.toLowerCase())}</Badge>
					}
					if (row.status === BotStatusType.Published) {
						if (row.liveStatus === BotLiveStatusType.offline) {
							return <Badge variant="red">{t(row.liveStatus.toLowerCase())}</Badge>
						}
						if (row.liveStatus === BotLiveStatusType.inactive) {
							return <Badge variant="muted">{t(row.liveStatus.toLowerCase())}</Badge>
						}
						return <Badge variant="green">{t(row.liveStatus.toLowerCase())}</Badge>
					}
				},
			},
			{
				id: 'author_id',
				name: t('videobot:botFields.createdBy.label'),
				sortable: true,
				selector: (row) => row.author?.email,
			},
			{
				id: 'id',
				name: t('videobot:botFields.createdAt.label'),
				sortable: true,
				selector: (row) => row.createdAt,
				cell: (row) => {
					return row.createdAt
						? format(new Date(`${row.createdAt}Z`), 'dd/MM/yyyy HH:mm')
						: '-'
				},
			},
			{
				id: 'published_at',
				name: t('videobot:botFields.currentRevision.label'),
				sortable: true,
				selector: (row) => row.publishedAt || row.createdAt,
				cell: (row) => {
					const revisionDate = row.publishedAt || row.createdAt
					return revisionDate
						? format(new Date(`${revisionDate}Z`), 'dd/MM/yyyy HH:mm')
						: '-'
				},
			},
		]

		return (
			<View header title={t('live_status')} mobileTopBar isLoading={isFetching}>
				<div className="mb-4 flex flex-col items-start gap-4 md:flex-row md:items-end">
					<form onSubmit={handleSubmit(onSearch)} className="flex gap-4">
						<label className="flex flex-col gap-2">
							<span className="select-none opacity-75">Search by name</span>
							<div className="flex gap-2">
								<FieldText
									type="text"
									className="rounded"
									placeholder="Search bots..."
									name="bot_name"
									register={register}
								/>
								<button
									className="rounded bg-primary px-8 text-body transition hover:bg-dark"
									type="submit"
								>
									Search
								</button>
							</div>
						</label>
					</form>

					<div className="mt-4 flex flex-col">
						<span className="mb-2 select-none opacity-75">
							{t('videobot:filterByLiveStatus')}
						</span>
						<div>
							<SelectPicker
								data={BotStatusFilter}
								value={botStatusFilter}
								onChange={(val) => setBotStatusFilter(val)}
								style={{ width: 224 }}
							/>
						</div>
					</div>

					{accounts && (
						<div className="mt-4 flex flex-col">
							<span className="mb-2 select-none opacity-75">
								{t('videobot:filterByAccounts')}
							</span>
							<div style={{ width: '100%' }}>
								<CheckPicker
									data={accounts.map((account) => ({
										label: account.businessName,
										value: account.id,
									}))}
									className="custom-checkpicker"
									value={accountFilter}
									onChange={(selectedValues) => {
										setAccountFilter(selectedValues)
									}}
									style={{
										width: 224,
										height: 'auto',
									}}
									placeholder={t('videobot:selectAccounts')}
								/>
							</div>
						</div>
					)}
				</div>
				<DataTable
					columns={columns}
					data={bots}
					pageSize={size}
					totalCount={data.metadata.totalCount}
					onPageChange={onPageChange}
					page={data.metadata.page}
					onSort={onSort}
					defaultSortFieldId={orderBy || 'name'}
					defaultSortAsc={orderDirection === 'asc'}
					onRowClicked={handleEdit}
					sortServer
				/>
			</View>
		)
	}

	return <Loader cover />
}

export const LiveStatusView = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin]}>
			<LiveStatusContents />
		</RoleCheck>
	)
}
