import {
	Button,
	Dialog,
	DialogTrigger,
	Popover,
	DisclosureGroup,
	Disclosure,
	Heading,
	DisclosurePanel,
} from 'react-aria-components'
import { Checkbox, CheckboxGroup, Radio, RadioGroup } from 'rsuite'
import { Fragment } from 'react'
import { capitalize, union, difference, intersection } from 'lodash-es'
import { FormProvider, useForm, useFormContext, Controller } from 'react-hook-form'

import Icon from '@/components/Icon'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import Loader from '@/components/Loader'
import {
	BotLiveStatusType,
	BotStatusType,
	EventChannelType,
	FeatureType,
} from '@/api/videobot.schemas'

const FilterDisclosure = ({ id, title, subTitle, children }) => {
	return (
		<Disclosure className="py-3" id={id}>
			{({ isExpanded }) => (
				<Fragment>
					<Heading className="m-0 flex flex-col gap-3">
						<span className="m-0 font-sans font-semibold">{title}</span>
						<Button className="flex w-full items-center" slot="trigger">
							<span className="font-sans text-sm font-normal text-muted">
								{subTitle}
							</span>
							<div className="ml-auto fill-muted">
								<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
							</div>
						</Button>
					</Heading>
					<DisclosurePanel className="py-2">{children}</DisclosurePanel>
				</Fragment>
			)}
		</Disclosure>
	)
}

const StatusFilter = () => {
	const { setValue, watch, control } = useFormContext()
	const watchStatus = watch('status')

	const publishedStatus = [
		BotLiveStatusType.live,
		BotLiveStatusType.offline,
		BotLiveStatusType.inactive,
	]

	const publishedCheckAll = (_, checked) => {
		if (checked) {
			setValue('status', union(watchStatus, publishedStatus))
		} else {
			setValue('status', difference(watchStatus, publishedStatus))
		}
	}

	return (
		<FilterDisclosure
			id="status"
			title="Status"
			subTitle={watchStatus.length > 0 ? watchStatus.map(capitalize).join(', ') : 'Status'}
		>
			<div className="flex flex-col rounded-sm border border-line">
				<Checkbox
					value="published"
					indeterminate={
						difference(publishedStatus, watchStatus).length > 0 &&
						intersection(publishedStatus, watchStatus).length > 0
					}
					checked={difference(publishedStatus, watchStatus).length === 0}
					onChange={publishedCheckAll}
				>
					Published
				</Checkbox>

				<Controller
					control={control}
					name="status"
					render={({ field: { onChange, value, ref } }) => (
						<CheckboxGroup
							className="flex flex-col"
							onChange={onChange}
							value={value}
							ref={ref}
						>
							<div className="flex flex-col pl-4">
								{publishedStatus.map((status) => (
									<Checkbox value={status} key={status}>
										{capitalize(status)}
									</Checkbox>
								))}
							</div>

							<Checkbox value={BotStatusType.Draft}>Draft</Checkbox>
							<Checkbox value={BotStatusType.Deactivated}>Deactivated</Checkbox>
						</CheckboxGroup>
					)}
				/>
			</div>
		</FilterDisclosure>
	)
}

const TypeFilter = () => {
	const activeAccount = useCurrentAccount()
	const { data: featureFlags } = useFeatureFlags({ account_id: activeAccount?.id })
	const { control, watch } = useFormContext()
	const channel = watch('channel')

	if (!featureFlags) {
		return <Loader />
	}

	const eventChannelTypes = Object.entries(EventChannelType)
		.filter(
			([_, value]) =>
				value !== 'slider' ||
				featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`),
		)
		.map(([label, value]) => ({
			label: capitalize(label),
			value,
		}))

	return (
		<FilterDisclosure id="type" title="Type" subTitle={capitalize(channel) || 'Type'}>
			<Controller
				name="channel"
				control={control}
				render={({ field: { value, onChange, ref } }) => (
					<RadioGroup
						className="flex flex-col gap-2 rounded-sm border border-line px-2 py-2"
						onChange={onChange}
						value={value}
						ref={ref}
					>
						{eventChannelTypes.map(({ label, value }) => (
							<Radio key={value} value={value}>
								{label}
							</Radio>
						))}
					</RadioGroup>
				)}
			/>
		</FilterDisclosure>
	)
}

export const AnalyticsAdvancedFilters = ({ onFilterApply, channel, status }) => {
	const form = useForm({
		defaultValues: {
			status: status || null,
			channel: channel || null,
		},
	})
	const watchStatus = form.watch('status')
	const watchType = form.watch('channel')
	const isClearable = Boolean(watchStatus?.length > 0 || !!watchType)
	let filterBadge = watchStatus.length

	if (watchType) {
		filterBadge += 1
	}

	const handleFilter = (data) => {
		onFilterApply({
			status: data.status.join(','),
			channel: data.channel,
		})
	}

	const resetFilter = () => {
		form.setValue('status', [])
		form.setValue('channel', null)

		onFilterApply({
			status: null,
			channel: null,
		})
	}

	return (
		<DialogTrigger>
			<Button className="h-full min-w-64">
				<div className="flex items-center rounded border border-line p-3">
					<span>Filters</span>
					<span className="ml-2 flex size-5 items-center justify-center rounded-full bg-global text-body">
						{filterBadge}
					</span>
					<div className="ml-auto fill-muted">
						<Icon name="chevron-down" />
					</div>
				</div>
			</Button>

			<Popover>
				<form onSubmit={form.handleSubmit(handleFilter)}>
					<FormProvider {...form}>
						<Dialog className="min-w-64 rounded border border-line bg-body px-3 py-1">
							<DisclosureGroup className="analytics-filter flex flex-col gap-2 divide-y divide-line">
								<StatusFilter />
								<TypeFilter />

								<div className="flex py-3">
									<button
										type="button"
										className={'text-red disabled:text-muted'}
										disabled={!isClearable}
										onClick={() => resetFilter()}
									>
										Clear filters
									</button>
									<button type="submit" className="ml-auto">
										Apply
									</button>
								</div>
							</DisclosureGroup>
						</Dialog>
					</FormProvider>
				</form>
			</Popover>
		</DialogTrigger>
	)
}
